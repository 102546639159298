const videos = {
    skim_video: "ecRPfEMQX84",
    panda_video: "656738287",
    crystal_video: "Q6msv54tAts",
    magritte_video: "710615098",
    ascension_video:"34JdKuMIrBw",
    reel_video: "921604071",
    ballmaze_video:"660827442"
};

export default videos;